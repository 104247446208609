import { FC, ReactNode, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../lib/AuthContext'
import { Header } from './Header'
import { Spinner } from './Spinner'

export const Layout: FC<{
  children: ReactNode
  withHeader?: boolean
  requiresAuthenticated?: boolean
}> = ({ children, withHeader = true, requiresAuthenticated = true }) => {
  const { isLoggedIn, user, loading, signOut } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !user.is_admin) {
      signOut().then(() => navigate('/'))
    }
  }, [navigate, signOut, user])

  if (requiresAuthenticated && !loading && !isLoggedIn) {
    return <Navigate replace to="/auth/signin" />
  }

  if (user && !user.is_admin) {
    return <Navigate replace to="/404" />
  }

  return (
    <div className="flex flex-col min-h-screen bg-bg">
      {withHeader && <Header />}

      <div className="flex-1 flex flex-col p-4 w-full">
        {isLoggedIn || !requiresAuthenticated ? (
          children
        ) : (
          <span className="m-auto">
            <Spinner />
          </span>
        )}
      </div>
    </div>
  )
}
