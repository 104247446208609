import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Button } from '../components/Button'
import EditIcon from '../components/EditIcon'
import { Layout } from '../components/Layout'
import { Modal, ModalTrigger } from '../components/Modal'
import { Table, TableColumn } from '../components/Table'
import { api } from '../lib/api'
import { queryClient } from '../lib/reactQuery'

type B2bLocation = {
  id: string
  created: Date
  updated_at: Date
  name: string
  street: string
  postal_code: string
  city: string
  discount_code: string
  departments: string[]
}

const columns: TableColumn<B2bLocation>[] = [
  {
    key: 'name',
    title: 'navn',
    render: (location) => location.name,
  },
  {
    key: 'address',
    title: 'Adresse',
    render: (location) =>
      `${location.street}, ${location.postal_code} ${location.city}`,
  },
  {
    key: 'departments',
    title: 'Avdelinger',
    render: (location) => location.departments.join(', '),
  },
  {
    key: 'discount_code',
    title: 'Kode',
    render: (location) => location.discount_code,
  },
  {
    key: 'edit',
    title: '',
    render: (location) => (
      <ModalTrigger
        button={({ openModal }) => (
          <button onClick={openModal} className="p-2">
            <EditIcon />
          </button>
        )}
        modal={({ closeModal }) => (
          <EditKindergartenModal
            existingB2bLocation={location}
            closeModal={closeModal}
          />
        )}
      />
    ),
    className: 'w-0',
  },
]

const Content = () => {
  const query = useQuery(['all-b2b-locations'], () =>
    api.get<{ b2bLocations: B2bLocation[] }>(`/admin/b2b-locations`)
  )

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center my-4">
        <h2 className="text-lg">Barnehager</h2>

        <ModalTrigger
          button={({ openModal }) => (
            <Button onClick={openModal}>Opprett ny</Button>
          )}
          modal={({ closeModal }) => (
            <EditKindergartenModal closeModal={closeModal} />
          )}
        />
      </div>

      <Table columns={columns} data={query.data?.b2bLocations ?? []} />
    </div>
  )
}

const EditKindergartenModal = ({
  existingB2bLocation,
  closeModal,
}: {
  existingB2bLocation?: B2bLocation
  closeModal: () => void
}) => {
  const [name, setName] = useState(existingB2bLocation?.name ?? '')
  const [street, setStreet] = useState(existingB2bLocation?.street ?? '')
  const [postalCode, setPostalCode] = useState(
    existingB2bLocation?.postal_code ?? ''
  )
  const [city, setCity] = useState(existingB2bLocation?.city ?? '')
  const [discountCode, setDiscountCode] = useState(
    existingB2bLocation?.discount_code ?? ''
  )
  const [departments, setDepartments] = useState(
    existingB2bLocation?.departments.join(', ') ?? ''
  )

  const mutation = useMutation(
    async () => {
      const params = {
        name,
        street,
        postalCode,
        city,
        discountCode,
        departments: departments
          .split(',')
          .map((s) => s.trim())
          .filter(Boolean),
      }

      if (existingB2bLocation) {
        return api.put<B2bLocation>(
          `/admin/b2b-locations/${existingB2bLocation.id}`,
          params
        )
      } else {
        return api.post<B2bLocation>(`/admin/b2b-locations`, params)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-b2b-locations')
        closeModal()
      },
    }
  )

  return (
    <Modal
      heading={`${existingB2bLocation ? 'Oppdater' : 'Opprett'} barnehage`}
      closeModal={closeModal}
    >
      <div className="space-y-4">
        <label className="flex flex-col space-y-1">
          <span>Navn</span>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder="Bakkeveien barnehage"
            className="p-2 border"
          />
        </label>

        <label className="flex flex-col space-y-1">
          <span>Adresse</span>
          <input
            value={street}
            onChange={(event) => setStreet(event.target.value)}
            placeholder="Bakkeveien 2B"
            className="p-2 border"
          />
        </label>

        <div className="flex space-x-4">
          <label className="flex-1 flex flex-col space-y-1">
            <span>Postnummer</span>
            <input
              value={postalCode}
              onChange={(event) => setPostalCode(event.target.value)}
              placeholder="01234"
              className="p-2 border"
            />
          </label>
          <label className="flex-1 flex flex-col space-y-1">
            <span>Poststed</span>
            <input
              value={city}
              onChange={(event) => setCity(event.target.value)}
              placeholder="Oslo"
              className="p-2 border"
            />
          </label>
        </div>

        <label className="flex flex-col space-y-1">
          <span>Kode</span>
          <input
            value={discountCode}
            onChange={(event) => setDiscountCode(event.target.value)}
            placeholder="ABC123ABC123"
            className="p-2 border"
          />
        </label>

        <label className="flex flex-col space-y-1">
          <span>Avdelinger</span>
          <input
            value={departments}
            onChange={(event) => setDepartments(event.target.value)}
            placeholder="F.eks. Rødkløver, Blåklokken, Smørblomst"
            className="p-2 border"
          />
          <span className="text-sm text-gray-500">
            Kommaseparert liste med navn på avdelingene i barnehagen
          </span>
        </label>

        <div className="flex justify-end items-center space-x-4 pt-4">
          <button onClick={closeModal}>Avbryt</button>
          <Button
            onClick={() => mutation.mutate()}
            loading={mutation.isLoading}
          >
            {existingB2bLocation ? 'Oppdater' : 'Opprett'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const KindergartensPage = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
