import { Link } from 'react-router-dom'
import { SubscriptionProductSerialNumberCell } from '../../components/CellEditableSerialNumber'
import { useStateMutation } from '../../components/CellEditableStatus'
import { Sorting, Table, TableColumn } from '../../components/Table'
import { Subscription, SubscriptionProductSerialNumber } from '../../lib/Types'
import { formatDate } from '../../utils/formatDate'
import { cx } from '../../utils/tailwind'
import { Color } from '../OrdersPage'
import { ProductRequest } from './DashboardPage'
import { ProductRequestTag } from './ProductRequestTag'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { api } from '../../lib/api'
import EditIcon from '../../components/EditIcon'

const subscriptionShippingDateMutation = (subscriptionId?: string) => {
  return useMutation<
    Subscription,
    unknown,
    {
      shippingDate: string
    }
  >(['subscription', subscriptionId], async (values) =>
    api.put<Subscription>(
      `/admin/subscriptions/shippingDate/${subscriptionId}`,
      values
    )
  )
}

export const ProductRequestsTable = ({
  data,
  sorting,
  setSorting,
}: {
  data: ProductRequest[]
  sorting: Sorting
  setSorting: (sorting: Sorting) => void
}) => {
  return (
    <div>
      {data.length > 0 ? (
        <Table
          data={data}
          columns={columns}
          sorting={sorting}
          onSort={setSorting}
        />
      ) : (
        <p>Ingen utsendelser akkurat nå</p>
      )}
    </div>
  )
}

const ConfirmSendPackage = ({
  subscriptionProductId,
  serialNumbers,
  subscriptionId,
}: {
  subscriptionProductId: string
  serialNumbers: SubscriptionProductSerialNumber[]
  subscriptionId: string
}) => {
  const mutate = useStateMutation()
  const isActive = Boolean(
    serialNumbers?.length >= 2 &&
      serialNumbers[0]?.serialNumber &&
      serialNumbers[1]?.serialNumber
  )

  const [confirmationStatus, setConfirmationStatus] = useState('')

  const handleEmailConfirmation = async (subscriptionId?: string) => {
    try {
      const response = api.put(
        `/admin/subscriptions/sendPackageOnTheWayMail/${subscriptionId}`,
        { subscriptionId }
      )
      console.log(response)
      setConfirmationStatus('Email sent til kunde')
    } catch (error) {
      console.error('Error sending email:', error)
      setConfirmationStatus('Error sending email')
    }
  }

  return (
    <div>
      {confirmationStatus}
      <button
        onClick={() => {
          mutate.mutateAsync({ subscriptionProductId, newStatus: 'active' })

          //TO DO: Send only one mail if multiple are orderd
          handleEmailConfirmation(subscriptionId)
        }}
        disabled={!isActive}
        className={cx(
          'border rounded-lg py-1 px-2 text-black',
          isActive ? 'bg-emerald-200' : 'text-theme-black-500'
        )}
      >
        Bekreft utsending
      </button>
    </div>
  )
}

const EditShippingDate = ({
  sendingDate,
  subscriptionId,
  isSwap,
}: {
  sendingDate: string
  subscriptionId: string
  isSwap: boolean
}) => {
  const [editshippingDateState, setEditshippingDateState] =
    useState<boolean>(false)

  const [shippingDate, setShippingDate] = useState<string>(
    new Date(sendingDate).toISOString().split('T')[0]
  )

  const updateSubscriptionStartDateMutation =
    subscriptionShippingDateMutation(subscriptionId)

  const EditSubscriptionStartDate = async () => {
    updateSubscriptionStartDateMutation.mutateAsync({
      shippingDate:
        new Date(shippingDate).toISOString().slice(0, 19).replace('T', ' ') +
        '+00',
    })
  }

  return (
    <div>
      {editshippingDateState ? (
        <div className="flex">
          <input
            className="border-2 border-black rounded-md ml-2"
            onChange={(e) => setShippingDate(e.target.value)}
            type="date"
            value={shippingDate}
            id="Utsendelsesdato"
            name="Utsendelsesdato"
            min={new Date().toISOString().split('T')[0]}
          ></input>
          <button
            onClick={() => {
              EditSubscriptionStartDate()
              setEditshippingDateState(false)
            }}
            className="ml-4 border-2 px-2 border-black rounded-md"
          >
            {' '}
            save
          </button>
        </div>
      ) : (
        <div>
          {formatDate(shippingDate, 'd. MMM yyyy')}
          {!isSwap && (
            <button
              onClick={() => setEditshippingDateState(true)}
              className="ml-4 border-2 p-1 border-black rounded-md"
            >
              <EditIcon />
            </button>
          )}
        </div>
      )}
    </div>
  )
}

const columns: TableColumn<ProductRequest>[] = [
  {
    key: 'shippingDate',
    sortable: true,
    title: 'Utsendelsesdato',
    render: (row) => (
      <div>
        <EditShippingDate
          sendingDate={row.shippingDate}
          isSwap={row.isSwapRequest}
          subscriptionId={row.subscription_id}
        ></EditShippingDate>
      </div>
    ),
  },
  {
    key: 'size',
    sortable: true,
    title: 'Parkdress',
    render: (row) => {
      return (
        <div className="space-y-[4px] flex flex-col">
          <div className="flex space-x-1 items-center">
            {row.color && row.color === 'green' ? (
              <Color
                col1="bg-[#CBE5C4]"
                col2="bg-[#869B81]"
                col3="bg-[#D97B5E]"
              />
            ) : (
              <Color
                col1="bg-[#0B6AD1]"
                col2="bg-[#F0244B]"
                col3="bg-[#F7E546]"
              />
            )}
            <div className="border text-center rounded px-2">{row.nameTag}</div>
            <div className="text-center">Str.&nbsp;{row.size}</div>
          </div>
          <div className="flex -m-1">
            <ProductRequestTag
              type={row.isSwapRequest ? 'swap' : 'new'}
              className="m-1"
            />
            <ProductRequestTag type={row.customerType} className="m-1" />
          </div>
        </div>
      )
    },
  },
  {
    key: 'Serial nummere',
    title: 'Serienummer',
    render: (row) => (
      <div className="space-y-2">
        <SubscriptionProductSerialNumberCell
          subscriptionProductId={row.id}
          serialNumbers={row.serialNumbers}
          defaultOpen={true}
        />
        <ConfirmSendPackage
          subscriptionProductId={row.id}
          serialNumbers={row.serialNumbers}
          subscriptionId={row.subscription_id}
        />
      </div>
    ),
  },
  {
    key: 'address',
    sortable: true,
    title: 'Mottaker',
    render: (row) => {
      if (row.customerType === 'b2b' && !row.isSwapRequest) {
        return (
          <div className="flex flex-col space-y-[4px]">
            <div>
              {row.b2bLocationName}, {row.b2bLocationDepartment}
            </div>
            <div>{row.fullShippingAddress}</div>
          </div>
        )
      } else {
        return <div>{row.fullShippingAddress}</div>
      }
    },
  },
  {
    key: 'created',
    sortable: true,
    title: 'Bestilling mottatt',
    render: (row) => formatDate(row.created),
  },
  {
    key: 'discountCode',
    title: 'Bestillingskode',
    render: (row) => row.orderDiscountCode,
  },
  {
    key: 'customer',
    sortable: true,
    title: 'Kunde',
    render: (row) => (
      <div className="flex flex-col space-y-1">
        <Link
          to={`/users/${row.userId}`}
          className="underline text-theme-blue-500"
        >
          {row.userName}
        </Link>
        <div>{row.userPhoneNumber}</div>
        <div>{row.userEmail}</div>
      </div>
    ),
    className: 'max-w-[160px]',
  },
]
