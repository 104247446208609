type EnvVars = {
  ENV: 'local' | 'test' | 'prod'
  BACKEND_URL: string
  SENTRY_DSN: string
}

const envVars: Record<EnvVars['ENV'], EnvVars> = {
  local: {
    ENV: 'local',
    BACKEND_URL: 'http://localhost:3001',
    SENTRY_DSN: '',
  },
  test: {
    ENV: 'test',
    BACKEND_URL: 'https://api.parkdressen.test.iterate.no',
    SENTRY_DSN:
      'https://aaf91aa4a2d7414ba9a7bf26260f4b27@o402078.ingest.sentry.io/4504236107431936',
  },
  prod: {
    ENV: 'prod',
    BACKEND_URL: 'https://api.parkdressen.no',
    SENTRY_DSN:
      'https://aaf91aa4a2d7414ba9a7bf26260f4b27@o402078.ingest.sentry.io/4504236107431936',
  },
}

export function envVar(variable: keyof EnvVars): string {
  const hostname = window.location.hostname

  let vars: EnvVars
  if (hostname.includes('localhost')) {
    vars = envVars.local
  } else if (hostname.includes('test')) {
    vars = envVars.test
  } else if (hostname.includes('app') || hostname.includes('parkdressen.no')) {
    vars = envVars.prod
  } else {
    throw new Error('Unknown environment')
  }

  return vars[variable]
}
