import { capitalize } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { Button } from '../../components/Button'
import { useStateMutation } from '../../components/CellEditableStatus'
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom'
import { TextInput } from '../../components/TextInput'
import { api } from '../../lib/api'
import { User } from '../../lib/AuthContext'
import { Subscription, SubscriptionProduct } from '../../lib/Types'
import { cx } from '../../utils/tailwind'

type ProductSearchResponse = {
  results: {
    user: User | null
    subscription: Subscription | null
    subscriptionProduct: SubscriptionProduct
  }[]
}

const Content = () => {
  const [innerWearQuery, setInnerWearQuery] = useState('')
  const [outerWearQuery, setOuterWearQuery] = useState('')
  const [onlyActive, setOnlyActive] = useState(true)

  const updateSubscriptionProductStatus = useStateMutation()

  const query = useMutation<ProductSearchResponse>(
    ['return-product', innerWearQuery, outerWearQuery, onlyActive],
    async () => {
      const params = [
        onlyActive ? 'only_active=true' : '',
        innerWearQuery ? `inner_wear=${innerWearQuery}` : '',
        outerWearQuery ? `outer_wear=${outerWearQuery}` : '',
      ]
        .filter(Boolean)
        .join('&')

      return api.get(`/admin/products/search?${params}`)
    },
    {
      onSettled: () => {
        updateSubscriptionProductStatus.reset()
      },
    }
  )

  const resetQuery = useCallback(() => {
    setInnerWearQuery('')
    setOuterWearQuery('')
    query.reset()
  }, [])

  const innerWearInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      if (e.ctrlKey) {
        if (e.key === 'i') {
          innerWearInputRef.current?.focus()
        } else if (e.key === 'c') {
          resetQuery()
          innerWearInputRef.current?.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [resetQuery])

  return (
    <div className="space-y-6">
      <h2 className="text-xl my-4">Retur av parkdress</h2>

      <form
        onSubmit={(event) => {
          event.preventDefault()
          query.mutate()
        }}
        className="space-y-2 px-6 py-4 bg-stone-200 rounded-lg"
      >
        <div>Søk etter serienummer</div>

        <div className="space-y-1">
          <div className="flex items-end space-x-2">
            <TextInput
              value={innerWearQuery}
              onValue={setInnerWearQuery}
              label="Indre"
              placeholder="F.eks. 123"
              className="w-28"
              autoFocus
              ref={innerWearInputRef}
            />
            <TextInput
              value={outerWearQuery}
              onValue={setOuterWearQuery}
              label="Ytre"
              placeholder="F.eks. 123"
              className="w-28"
            />
            <Button type="submit" className="h-[44px]">
              Søk
            </Button>
            <button
              type="button"
              onClick={() => {
                resetQuery()
              }}
              className="h-[44px]"
            >
              Avbryt
            </button>
          </div>

          <label className="block">
            <input
              type="checkbox"
              checked={onlyActive}
              onChange={() => setOnlyActive(!onlyActive)}
            />
            <span className="ml-2">Kun aktive parkdresser</span>
          </label>
        </div>

        <div className="text-xs opacity-20 hover:text-sm hover:opacity-100 h-1">
          Hint: Trykk{' '}
          <span className="bg-theme-lilac-500 px-1 rounded">Ctrl + i</span> for
          å hoppe til søkefeltet, og bruk{' '}
          <span className="bg-theme-lilac-500 px-1 rounded">Ctrl + c</span> for
          å avbryte søket
        </div>
      </form>

      <div className="space-y-4">
        <h3 className="text-lg">Resultat(er)</h3>

        {query.data?.results.map((item) => (
          <div
            key={item.subscriptionProduct.id}
            className="rounded-md bg-slate-100 px-6 py-4"
          >
            <div className="space-y-4">
              <div className="flex">
                <div className="flex-1">
                  <div className="font-semibold">Parkdress</div>
                  <div>Størrelse: {item.subscriptionProduct.size}</div>
                  <div>
                    Status:{' '}
                    {capitalize(
                      item.subscriptionProduct.status.split('-').join(' ')
                    )}
                  </div>
                  <div className="flex items-center space-x-2">
                    <div>Serienummer:</div>
                    {item.subscriptionProduct.serial_numbers?.map(
                      (serialNumber) => (
                        <div
                          key={serialNumber.type}
                          className={cx(
                            'rounded bg-gray-300 px-2',
                            serialNumber.type === 'innerWear' &&
                              innerWearQuery === serialNumber.serialNumber &&
                              'bg-theme-green-300',
                            serialNumber.type === 'outerWear' &&
                              outerWearQuery === serialNumber.serialNumber &&
                              'bg-theme-green-300'
                          )}
                        >
                          {
                            {
                              innerWear: 'Indre',
                              outerWear: 'Ytre',
                              unknown: 'Ukjent',
                            }[serialNumber.type]
                          }
                          : {serialNumber.serialNumber}
                        </div>
                      )
                    )}
                  </div>
                </div>

                {item.subscriptionProduct.status !== 'returned' && (
                  <div className="flex-1">
                    <div>Marker denne parkdressen som returnert</div>
                    <div className="text-xs">
                      OBS! Gjelder både ytter- og innerdress
                    </div>

                    <div className="flex items-center space-x-4 mt-2">
                      <Button
                        onClick={() => {
                          updateSubscriptionProductStatus.mutate({
                            subscriptionProductId: item.subscriptionProduct.id,
                            newStatus: 'returned',
                          })
                        }}
                        disabled={updateSubscriptionProductStatus.isSuccess}
                      >
                        Marker som returnert
                      </Button>

                      {updateSubscriptionProductStatus.isSuccess && (
                        <div className="text-theme-green-500">OK</div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <div className="font-semibold">Kunde</div>
                  <div>{item.user?.name}</div>
                  <div>{item.user?.email}</div>
                  <div>{item.user?.phone_number}</div>

                  <div>
                    {item.user?.street}, {item.user?.postal_code}{' '}
                    {item.user?.city}
                  </div>
                  <div className="mt-4">
                    {item.user && (
                      <Link to={`/users/${item.user.id}`}>
                        <Button
                          disabled={updateSubscriptionProductStatus.isSuccess}
                        >
                          Gå til kunde
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <div className="font-semibold">Abonnement</div>
                  <div>Navnelapp: {item.subscription?.product_name_tag}</div>
                  <div>
                    Status:{' '}
                    {capitalize(
                      item.subscription?.subscription_status
                        .split('-')
                        .join(' ')
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {(!query.data || query.data.results.length === 0) && (
          <p>Ingen resultater</p>
        )}
      </div>
    </div>
  )
}

export const ReturnPage = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
