import { FC, useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { Navigate } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { Spinner } from '../../components/Spinner'
import { api } from '../../lib/api'
import { useAuthContext } from '../../lib/AuthContext'

const Form = () => {
  const [email, setEmail] = useState('')

  const { mutateAsync, isLoading, isSuccess } = useMutation<
    unknown,
    unknown,
    { email: string }
  >(async ({ email }) => {
    return api.post('/auth/signin', {
      email,
      service: 'admin-web',
      returnTo: '/dashboard',
    })
  })

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await mutateAsync({ email }).then(() => {
        setEmail('')
      })
    },
    [email, mutateAsync]
  )

  return (
    <form
      onSubmit={onSubmit}
      className="p-4 border rounded border-black flex-1 max-w-xs space-y-4"
    >
      <label className="flex flex-col">
        <span>Email</span>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
          className="border rounded border-black px-2 py-1"
        />
      </label>

      <button
        type="submit"
        className="bg-theme-blue-500 text-white px-2 py-1 rounded"
        disabled={isLoading}
      >
        Sign in
      </button>

      {isSuccess ? (
        <div>Sign in by clicking the link we sent to your email</div>
      ) : isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : null}
    </form>
  )
}

const Content: FC = () => {
  const { isLoggedIn } = useAuthContext()

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="mt-8 flex flex-1 justify-center items-start">
      <Form />
    </div>
  )
}

export const SigninPage: FC = () => {
  return (
    <Layout requiresAuthenticated={false}>
      <Content />
    </Layout>
  )
}
