import { cx } from '../utils/tailwind'
import { Spinner } from './Spinner'

export type TableColumn<T> = {
  key: string
  title: string
  render: (row: T) => React.ReactNode
  sortable?: boolean
  className?: string
}

export type TableColumns<T> = TableColumn<T>[]

export type Sorting = {
  key: string
  order: 'asc' | 'desc'
}

export type TableProps<T> = {
  data: T[]
  columns: TableColumn<T>[]
  sorting?: Sorting
  onSort?: (sorting: Sorting) => void
  loading?: boolean
  fetchMore?: () => void
}

export const Table = <T extends { id: string | number }>({
  data,
  columns,
  sorting,
  onSort,
  loading,
  fetchMore,
}: TableProps<T>) => {
  return (
    <div className="relative shadow-md sm:rounded-lg w-full snap-x">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {columns.map(({ key, title, sortable, className }) => (
              <th key={key} className={cx('px-6 py-4', className)}>
                <span>{title}</span>
                {sortable && sorting && onSort && (
                  <SortButton sortKey={key} sorting={sorting} onSort={onSort} />
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row) => (
            <tr key={row.id} className="odd:bg-white even:bg-gray-50 border-b">
              {columns.map(({ key, render, className }) => (
                <td key={key} className={cx('px-6 py-4', className)}>
                  {render(row)}
                </td>
              ))}
            </tr>
          ))}

          {loading && (
            <tr className="bg-white border-b">
              <td colSpan={columns.length} className="px-6 py-4">
                <div className="flex justify-center">
                  <Spinner />
                </div>
              </td>
            </tr>
          )}
          {!loading && fetchMore && (
            <tr className="bg-white border-b">
              <td colSpan={columns.length} className="px-6 py-4">
                <div className="flex justify-center">
                  <button
                    onClick={fetchMore}
                    className="bg-blue-900 text-white px-2 py-1 rounded"
                  >
                    Fetch more
                  </button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const SortButton = ({
  sortKey,
  sorting,
  onSort,
}: {
  sortKey: string
  sorting: Sorting
  onSort: (sorting: Sorting) => void
}) => {
  return (
    <button
      onClick={() =>
        onSort({
          key: sortKey,
          order:
            sorting.key === sortKey
              ? sorting.order === 'desc'
                ? 'asc'
                : 'desc'
              : 'asc',
        })
      }
      className="inline p-2 w-6"
    >
      {sorting.key === sortKey ? (sorting.order === 'desc' ? '▲' : '▼') : '○'}
    </button>
  )
}
