import { flatten, sum } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { Sorting, Table, TableColumn } from '../components/Table'
import { TextInput } from '../components/TextInput'
import { api } from '../lib/api'
import { Order } from '../lib/Types'
import { formatDate } from '../utils/formatDate'
import { formatPrice } from '../utils/formatPrice'
import useDebounce from '../utils/useDebounce'
import { ProductRequestTag } from './DashboardPage/ProductRequestTag'

const columns: TableColumn<Order>[] = [
  {
    key: 'id',
    title: 'ID',
    render: (order) => {
      return (
        <div className="max-w-[80px] overflow-hidden text-ellipsis block max-h-4">
          {order.id}
        </div>
      )
    },
  },

  {
    key: 'userId',
    title: 'Bruker-ID',
    render: (order) => {
      return (
        <Link
          to={`/users/${order.user_id}`}
          className="underline text-blue-500 max-w-[80px] overflow-hidden text-ellipsis block max-h-4"
        >
          {order.user_id}
        </Link>
      )
    },
  },
  {
    key: 'created',
    title: 'Bestilling mottatt',
    render: (order) => formatDate(order.created),
    sortable: true,
  },
  {
    key: 'name',
    title: 'navn',
    render: (order) => order.name,
    sortable: true,
  },
  {
    key: 'email',
    title: 'E-post',
    render: (order) => order.email,
  },
  {
    key: 'address',
    title: 'Adresse',
    render: (order) => `${order.street}, ${order.postal_code} ${order.city}`,
  },
  {
    key: 'products',
    title: 'Produkter',
    render: (order) => {
      return (
        <div className="flex flex-col space-y-1 justify-start">
          {order.products.map((product) => {
            return (
              <div key={product.name} className="flex space-x-1 items-center">
                {product.color && product.color === 'green' ? (
                  <Color
                    col1="bg-[#CBE5C4]"
                    col2="bg-[#869B81]"
                    col3="bg-[#D97B5E]"
                  />
                ) : (
                  <Color
                    col1="bg-[#0B6AD1]"
                    col2="bg-[#F0244B]"
                    col3="bg-[#F7E546]"
                  />
                )}
                <div className="border text-center rounded px-2">
                  {product.name}
                </div>
                <div className="text-center">Str.&nbsp;{product.size}</div>
              </div>
            )
          })}
        </div>
      )
    },
  },
  {
    key: 'price',
    title: 'Pris',
    render: (order) => formatPrice(order.price_minor),
  },
  {
    key: 'discountCode',
    title: 'Bestillingskode',
    render: (order) => order.discount_code,
  },
  {
    key: 'customerType',
    title: 'Kundetype',
    render: (order) => (
      <div className="flex">
        <ProductRequestTag type={order.customer_type} />
      </div>
    ),
  },
]

const Content = () => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)
  const [sorting, setSorting] = useState<Sorting>({
    key: 'created',
    order: 'desc',
  })
  const ordersQuery = useInfiniteQuery(
    ['all-orders', sorting.key, sorting.order, debouncedQuery],
    ({ pageParam = 0 }) =>
      api.get<{ data: Order[]; hasMoreData: boolean }>(
        `/admin/orders?limit=100&offset=${pageParam}&sort_by=${sorting.key}&order_by=${sorting.order}&query=${debouncedQuery}`
      ),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.hasMoreData
          ? sum(pages.map((page) => page.data.length))
          : undefined,
    }
  )

  const orders = flatten(ordersQuery.data?.pages.map((page) => page.data) ?? [])

  return (
    <div>
      <TextInput
        value={query}
        onValue={setQuery}
        placeholder="Søk etter bestillingskode"
        className="mb-4"
      />
      <Table
        data={orders}
        columns={columns}
        sorting={sorting}
        onSort={setSorting}
        loading={
          ordersQuery.isLoading ||
          ordersQuery.isFetching ||
          ordersQuery.isFetchingNextPage
        }
        fetchMore={
          ordersQuery.hasNextPage ? ordersQuery.fetchNextPage : undefined
        }
      />
    </div>
  )
}

export const OrdersPage = () => {
  return (
    <Layout>
      <h2 className="text-lg my-4">Orders</h2>
      <Content />
    </Layout>
  )
}

export const Color: FC<{
  col1: string
  col2: string
  col3: string
  selected?: boolean
}> = ({ col1, col2, col3, selected }) => (
  <div className={`rounded-full ml-2`}>
    <div className="flex w-4 h-4 rounded-full overflow-hidden border-black-900 border-2">
      <div className={`${col1} w-1/3 h-full`} />
      <div className={`${col2} w-1/3 h-full`} />
      <div className={`${col3} w-1/3 h-full`} />
    </div>
  </div>
)
