import { forwardRef, ReactNode } from 'react'
import { cx } from '../utils/tailwind'

export type FormInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  onValue: (value: string) => void
  label?: string | ReactNode
  description?: string
}

export const TextInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ value, onValue, label, description, className, ...rest }, ref) => {
    return (
      <label className={cx('flex flex-col space-y-1', className)}>
        {label && (
          <div className="flex justify-between items-end">
            <span className="text-sm">{label}</span>
          </div>
        )}

        <div
          className={cx(
            'relative', // for the success icon
            'flex flex-col' // to make the input stretch
          )}
        >
          <input
            value={value}
            onChange={(event) => onValue(event.target.value)}
            className={cx(
              'text-base border-2 border-theme-black-700 rounded px-3 h-11'
            )}
            ref={ref}
            {...rest}
          />
        </div>

        {description && (
          <p className="text-xs leading-1 text-black-500">{description}</p>
        )}
      </label>
    )
  }
)
