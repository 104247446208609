import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Layout } from '../../components/Layout'
import { Sorting } from '../../components/Table'
import { api } from '../../lib/api'
import { SubscriptionProductSerialNumber } from '../../lib/Types'
import { useProductRequestsFilters } from './ProductRequestFilters'
import { ProductRequestsTable } from './ProductRequestsTable'

export type ProductRequest = {
  id: string
  subscription_id: string
  created: string
  subscriptionProductId: string
  orderId: string
  shippingDate: string
  size: string
  color?: 'red' | 'green'
  nameTag: string
  customerType: 'b2b' | 'b2c'
  isSwapRequest: boolean
  serialNumbers: SubscriptionProductSerialNumber[]
  fullShippingAddress: string
  userId: string
  userName: string
  userPhoneNumber: string
  userEmail: string
  b2bLocationId?: string
  b2bLocationName?: string
  b2bLocationDepartment?: string
  orderDiscountCode?: string
}
const Content = () => {
  const navigate = useNavigate()

  const [sorting, setSorting] = useState<Sorting>({
    key: 'shippingDate',
    order: 'asc',
  })

  const { FilterComponent, filterProductRequests } = useProductRequestsFilters()

  const response = useQuery(
    ['product-requests', sorting.key, sorting.order],
    async () =>
      api.get<{ productRequests: ProductRequest[] }>(
        `/admin/orders/product-requests?sort_by=${sorting.key}&order_by=${sorting.order}`
      ),
    { keepPreviousData: true }
  )

  const productRequests = useMemo(() => {
    return filterProductRequests(response.data?.productRequests ?? [])
  }, [response])

  return (
    <div className="space-y-4">
      <div className="flex justify-between my-4">
        <h2 className="text-lg">
          Parkdresser til utsending (
          <b>
            {productRequests && productRequests.length > 0
              ? productRequests.length
              : 'Ingen'}{' '}
            {productRequests && productRequests.length === 1 ? 'ny' : 'nye'}
          </b>
          )
        </h2>

        <Button onClick={() => navigate('/product-return')}>
          Retur av parkdress
        </Button>
      </div>

      {FilterComponent}

      <ProductRequestsTable
        data={productRequests}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  )
}

export const DashboardPage = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
