import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

type ModalProps = {
  heading: string
  children: ReactNode
  closeModal: () => void
}
export const Modal: FC<ModalProps> = ({ heading, closeModal, children }) => {
  useEffect(() => {
    function listener(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeModal()
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [])

  const modal = (
    <div>
      <div
        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 animate-in fade-in"
        onClick={closeModal}
      >
        <div
          className="w-full max-w-xl my-6 mx-4"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="rounded-lg shadow-lg w-full bg-white">
            <div className="flex items-start justify-between p-6 border-b border-slate-200">
              <h3 className="text-xl">{heading}</h3>
              <button onClick={closeModal}>
                <div className="text-black h-6 w-6 text-2xl">×</div>
              </button>
            </div>

            <div className="p-6">{children}</div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  )

  const modalRoot = document.getElementById('modal-root')!

  return ReactDOM.createPortal(modal, modalRoot)
}

export type ModalTriggerProps = {
  button: (params: { openModal: () => void }) => ReactNode
  modal: (params: { closeModal: () => void }) => ReactNode
  initialIsOpen?: boolean
}
export const ModalTrigger = ({
  button,
  modal,
  initialIsOpen = false,
}: ModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])
  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      {button({ openModal })}
      {isOpen && modal({ closeModal })}
    </>
  )
}
