import { Link, NavLink } from 'react-router-dom'
import { useAuthContext } from '../lib/AuthContext'
import { Menu } from './Menu'

export const Header = () => {
  const { isLoggedIn, user } = useAuthContext()

  return (
    <>
      <div className="h-28" /> {/**spacer for fixed menu */}
      <div className="p-4 bg-theme-green-500 fixed z-50 w-full h-28">
        <div className="flex justify-between items-center">
          <Link to="/">
            <h1 className="text-xl">Parkdressen admin</h1>
          </Link>

          {isLoggedIn && user ? (
            <>
              <div>
                <span>{user.email}</span>
                {' | '}
                <Link to="/auth/signout" className="underline">
                  Sign out
                </Link>
              </div>
            </>
          ) : (
            <NavLink
              to="/auth/signin"
              className={(isActive) => (isActive ? 'hidden' : '')}
            >
              Sign in
            </NavLink>
          )}
        </div>

        {isLoggedIn && (
          <div className="flex justify-center items-center mt-2">
            <Menu />
          </div>
        )}
      </div>
    </>
  )
}
