/**
 * Compose className from tailwind classes conditionally
 *
 * @example
 * ```ts
 * <div className={cx('text-lg', hasError && 'text-red-600')} />
 * ```
 */
export function cx(...args: (string | boolean | null | undefined)[]): string {
  return args.filter((item) => item && typeof item === 'string').join(' ')
}
