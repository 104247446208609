import { FC } from 'react'
import { Layout } from '../components/Layout'

export const NotFoundPage: FC = () => {
  return (
    <Layout requiresAuthenticated={false}>
      Didn't find what you're looking for...
    </Layout>
  )
}
