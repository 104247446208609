import { cx } from '../../utils/tailwind'

export const ProductRequestTag = ({
  type,
  className,
}: {
  type: 'new' | 'swap' | 'b2b' | 'b2c'
  className?: string
}) => {
  const bg = {
    new: 'bg-blue-500',
    swap: 'bg-green-500',
    b2b: 'bg-fuchsia-500',
    b2c: 'bg-cyan-500',
  }[type]

  return (
    <div className={cx('px-2 text-white rounded text-sm', bg, className)}>
      {type.toUpperCase()}
    </div>
  )
}
