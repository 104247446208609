import { useState } from 'react'
import { useMutation } from 'react-query'
import { api } from '../lib/api'
import { queryClient } from '../lib/reactQuery'
import { SubscriptionProduct } from '../lib/Types'
import EditIcon from './EditIcon'

export const useStateMutation = () => {
  return useMutation<
    unknown,
    unknown,
    {
      subscriptionProductId: SubscriptionProduct['id']
      newStatus: SubscriptionProduct['status']
    }
  >(
    async (args) => {
      return api.post(
        `/admin/products/updateStatus/${args.subscriptionProductId}`,
        {
          status: args.newStatus,
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('order')
        queryClient.invalidateQueries('product-requests')
        queryClient.invalidateQueries('user')
      },
    }
  )
}

export const SubscriptionProductStatusCell = ({ row }: any) => {
  const [isEditable, setIsEditable] = useState(false)
  const [status, setStatus] = useState(row.status)

  const mutation = useStateMutation()

  return (
    <div className="flex items-center">
      <select
        className={`${
          isEditable ? 'text-black border-2 border-black disabled:true' : ''
        }`}
        name="status"
        id="status"
        disabled={!isEditable}
        onChange={(e) => setStatus(e.target.value)}
        value={status}
      >
        <option value="waiting-for-sending">waiting-for-sending</option>
        <option value="active">active</option>
        <option value="returning">returning</option>
        <option value="returned">returned</option>
      </select>

      <button
        className="border-2 border-black p-1 ml-1 rounded-md text-black"
        onClick={(e) => {
          e.preventDefault()
          if (isEditable) {
            mutation.mutateAsync({
              subscriptionProductId: row.id,
              newStatus: status,
            })
          }

          setIsEditable(!isEditable)
        }}
      >
        {!isEditable && <EditIcon />}
        {isEditable && <p className="text-xs">Save</p>}
      </button>
    </div>
  )
}
