import { useState } from 'react'
import { useMutation } from 'react-query'
import { api } from '../lib/api'
import { queryClient } from '../lib/reactQuery'
import {
  SubscriptionProduct,
  SubscriptionProductSerialNumber,
} from '../lib/Types'
import EditIcon from './EditIcon'

const useSerialNumberMutation = () => {
  return useMutation<
    unknown,
    unknown,
    {
      subscriptionProductId: SubscriptionProduct['id']
      newSerialNumber: SubscriptionProduct['serial_numbers']
    }
  >(
    async (args) => {
      return api.post(
        `/admin/products/updateSerialNumber/${args.subscriptionProductId}`,
        {
          serialNumber: args.newSerialNumber,
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('order')
        queryClient.invalidateQueries('user')
        queryClient.invalidateQueries('product-requests')
      },
    }
  )
}

export const SubscriptionProductSerialNumberCell = ({
  subscriptionProductId,
  serialNumbers,
  defaultOpen,
}: {
  subscriptionProductId: SubscriptionProduct['id']
  serialNumbers: SubscriptionProductSerialNumber[]
  defaultOpen: boolean
}) => {
  const [isEditable, setIsEditableSN] = useState(defaultOpen)

  let initial = serialNumbers
  if (!initial) {
    initial = [
      { type: 'innerWear', serialNumber: '' },
      { type: 'outerWear', serialNumber: '' },
    ]
  }
  const [serialNumberInner, setSerialNumberInner] = useState(
    initial[0].serialNumber
  )
  const [serialNumberOuter, setSerialNumberOuter] = useState(
    initial[1].serialNumber
  )

  const mutation = useSerialNumberMutation()

  return (
    <div className="flex items-center">
      <p className="mr-2">Innerdress</p>
      <input
        value={serialNumberInner}
        onChange={(e) => setSerialNumberInner(e.target.value)}
        className={`${
          isEditable ? 'text-black border-2 border-black w-16' : 'w-16'
        }`}
        disabled={!isEditable}
      />
      <p className="ml-2 mr-2">Ytterdress</p>
      <input
        value={serialNumberOuter}
        onChange={(e) => setSerialNumberOuter(e.target.value)}
        className={`${
          isEditable ? 'text-black border-2 border-black w-16' : 'w-16'
        }`}
        disabled={!isEditable}
      />
      <button
        className="border-2 border-black p-1 ml-1 rounded-md text-black"
        onClick={(e) => {
          e.preventDefault()
          if (isEditable) {
            mutation.mutateAsync({
              subscriptionProductId: subscriptionProductId,
              newSerialNumber: [
                { type: 'innerWear', serialNumber: serialNumberInner },
                { type: 'outerWear', serialNumber: serialNumberOuter },
              ],
            })
          }
          setIsEditableSN(!isEditable)
        }}
      >
        {!isEditable && <EditIcon />}
        {isEditable && <p className="text-xs">Lagre</p>}
      </button>
    </div>
  )
}
