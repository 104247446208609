import { QueryClientProvider } from 'react-query'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './lib/AuthContext'
import { queryClient } from './lib/reactQuery'
import { SigninPage } from './pages/auth/SigninPage'
import { SignoutPage } from './pages/auth/SignoutPage'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'
import { DiscountsPage } from './pages/DiscountsPage/DiscountsPage'
import { KindergartensPage } from './pages/KindergartensPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { OrderPage } from './pages/OrderPage'
import { OrdersPage } from './pages/OrdersPage'
import { ReturnPage } from './pages/ProductReturnPage/ProductReturnPage'
import { UserPage } from './pages/UserPage/UserPage'
import { UsersPage } from './pages/UsersPage'

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/orders/:orderId" element={<OrderPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/:userId" element={<UserPage />} />
      <Route path="/kindergartens" element={<KindergartensPage />} />
      <Route path="/discounts" element={<DiscountsPage />} />
      <Route path="/product-return" element={<ReturnPage />} />

      <Route path="/auth/signin" element={<SigninPage />} />
      <Route path="/auth/signout" element={<SignoutPage />} />
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Router />
      </AuthContextProvider>
    </QueryClientProvider>
  )
}

export default App
