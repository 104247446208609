import { useMutation } from 'react-query'
import { Button } from '../../components/Button'
import { Modal, ModalTrigger } from '../../components/Modal'
import { api } from '../../lib/api'
import { queryClient } from '../../lib/reactQuery'
import { Subscription } from '../../lib/Types'

export const useCancelSubscription = () => {
  return useMutation<
    unknown,
    unknown,
    {
      subscriptionId: Subscription['id']
    }
  >(
    async (args) => {
      return api.put(`/admin/subscriptions/cancel/${args.subscriptionId}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('product-requests')
        queryClient.invalidateQueries('user')
      },
    }
  )
}

export const CancelSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string
}) => {
  const mutation = useCancelSubscription()

  return (
    <ModalTrigger
      button={({ openModal }) => (
        <Button onClick={openModal}>Kanseller abonnement</Button>
      )}
      modal={({ closeModal }) => (
        <Modal heading="Kanseller abonnement" closeModal={closeModal}>
          <p>Er du sikker på at du vil kansellere abonnementet?</p>

          <div className="flex justify-end items-center space-x-4">
            <button onClick={closeModal}>Avbryt</button>
            <Button onClick={() => mutation.mutate({ subscriptionId })}>
              Kanseller
            </Button>
          </div>
        </Modal>
      )}
    />
  )
}
