import React, { useCallback } from 'react'
import { useQuery } from 'react-query'
import { api } from './api'
import { queryClient } from './reactQuery'

export type User = {
  id: string
  phone_number: string
  email: string
  name?: string
  street?: string
  postal_code?: string
  city?: string
  is_admin: boolean
  email_marketing: boolean
}

type AuthContextType = {
  loading: boolean
  isLoggedIn: boolean
  user: User | undefined
  signOut: () => Promise<void>
}
export const AuthContext = React.createContext<AuthContextType>({
  loading: true,
  isLoggedIn: false,
  user: undefined,
  signOut: () => Promise.resolve(),
})

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { data, isLoading } = useQuery(['loggedInUser'], () =>
    api.get<User>('/users/me')
  )

  const signOut = useCallback(async () => {
    api.delete('/auth/signout').then(() => queryClient.clear())
  }, [])

  const value = React.useMemo(() => {
    return {
      loading: isLoading,
      isLoggedIn: Boolean(data),
      user: data,
      signOut,
    }
  }, [data, isLoading, signOut])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
