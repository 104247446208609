import { addMonths, isBefore, isWithinInterval, parseISO } from 'date-fns'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Button } from '../../components/Button'
import EditIcon from '../../components/EditIcon'
import { Layout } from '../../components/Layout'
import { Modal, ModalTrigger } from '../../components/Modal'
import { Table, TableColumns } from '../../components/Table'
import { api } from '../../lib/api'
import { queryClient } from '../../lib/reactQuery'
import { formatDate } from '../../utils/formatDate'
import { formatPrice } from '../../utils/formatPrice'

export type DiscountDurationMonths = -1 | number // -1 = forever, number = months

export type Discount = {
  id: string
  code: string
  type: 'percentage' | 'fixed'
  percentageOff?: number // 0-100 inclusive
  fixedAmountMinorOff?: number // in øre
  durationMonths: DiscountDurationMonths
  validForNewSubscriptionsFrom: string
  validForNewSubscriptionsTo: string
}

const columns: TableColumns<Discount> = [
  {
    key: 'code',
    title: 'Kode',
    render: (discount) => discount.code,
  },
  {
    key: 'description',
    title: 'Beskrivelse',
    render: (discount) => {
      if (discount.type === 'percentage') {
        return (
          <div>
            {discount.percentageOff}% rabatt{' '}
            {discount.durationMonths === -1
              ? 'for alltid'
              : `${discount.durationMonths} måned${
                  discount.durationMonths === 1 ? '' : 'er'
                }`}
          </div>
        )
      } else {
        return (
          <div>
            {formatPrice(discount.fixedAmountMinorOff!)} rabatt per parkdress{' '}
            {discount.durationMonths === -1
              ? 'for alltid'
              : `${discount.durationMonths} måned${
                  discount.durationMonths === 1 ? '' : 'er'
                }`}
          </div>
        )
      }
    },
  },
  {
    key: 'valid',
    title: 'Gyldig',
    render: (discount) => {
      return (
        <div>
          {formatDate(
            parseISO(discount.validForNewSubscriptionsFrom),
            'dd. MMM yyyy'
          )}
          -
          {formatDate(
            parseISO(discount.validForNewSubscriptionsTo),
            'dd. MMM yyyy'
          )}
        </div>
      )
    },
  },
  {
    key: 'active',
    title: 'Aktiv',
    className: 'text-right',
    render: (discount) => {
      const discountIsActive = isWithinInterval(new Date(), {
        start: new Date(discount.validForNewSubscriptionsFrom),
        end: new Date(discount.validForNewSubscriptionsTo),
      })

      return discountIsActive ? '✅' : '❌'
    },
  },

  {
    key: 'edit',
    title: '',
    render: (discount) => (
      <ModalTrigger
        button={({ openModal }) => (
          <button onClick={openModal} className="p-2">
            <EditIcon />
          </button>
        )}
        modal={({ closeModal }) => (
          <EditDiscountModal
            existingDiscount={discount}
            closeModal={closeModal}
          />
        )}
      />
    ),
    className: 'w-0',
  },
]
const Content = () => {
  const query = useQuery(['all-discounts'], () =>
    api.get<{ data: Discount[] }>(`/admin/discounts`)
  )

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center my-4">
        <h2 className="text-lg">Rabattkoder</h2>

        <ModalTrigger
          button={({ openModal }) => (
            <Button onClick={openModal}>Opprett ny</Button>
          )}
          modal={({ closeModal }) => (
            <EditDiscountModal closeModal={closeModal} />
          )}
        />
      </div>

      <Table columns={columns} data={query.data?.data ?? []} />
    </div>
  )
}

const EditDiscountModal = ({
  existingDiscount,
  closeModal,
}: {
  existingDiscount?: Discount
  closeModal: () => void
}) => {
  const [code, setCode] = useState(existingDiscount?.code ?? '')
  const [type, setType] = useState(existingDiscount?.type ?? 'percentage')
  const [percentageOffString, setPercentageOffString] = useState(
    String(existingDiscount?.percentageOff ?? '')
  )
  const [fixedAmountMajorOffString, setFixedAmountMajorOffString] = useState(
    existingDiscount?.fixedAmountMinorOff
      ? String(existingDiscount.fixedAmountMinorOff / 100)
      : ''
  )
  const [durationMonths, setDurationMonths] = useState(
    existingDiscount?.durationMonths ?? 1
  )
  const [validForNewSubscriptionsFrom, setValidForNewSubscriptionsFrom] =
    useState(
      formatDate(
        existingDiscount?.validForNewSubscriptionsFrom
          ? parseISO(existingDiscount.validForNewSubscriptionsFrom)
          : new Date(),
        'yyyy-MM-dd'
      )
    )
  const [validForNewSubscriptionsTo, setValidForNewSubscriptionsTo] = useState(
    formatDate(
      existingDiscount?.validForNewSubscriptionsTo
        ? parseISO(existingDiscount.validForNewSubscriptionsTo)
        : addMonths(new Date(), 1),
      'yyyy-MM-dd'
    )
  )

  const percentageOff = percentageOffString
    ? parseFloat(percentageOffString)
    : 0
  const fixedAmountMinorOff = fixedAmountMajorOffString
    ? Math.round(parseFloat(fixedAmountMajorOffString) * 100)
    : 0

  const errorMessages: string[] = []
  if (!code) {
    errorMessages.push('Kode må fylles ut')
  }
  if (type === 'percentage' && percentageOff < 1) {
    errorMessages.push('Prosent må være større enn 0%')
  }
  if (type === 'fixed' && fixedAmountMinorOff < 1) {
    errorMessages.push('Beløp må være større enn 0,-')
  }
  if (
    isBefore(
      new Date(validForNewSubscriptionsTo),
      new Date(validForNewSubscriptionsFrom)
    )
  ) {
    errorMessages.push('Fra dato må være før til dato')
  }

  const [submitAttempted, setSubmitAttempted] = useState(false)
  const mutation = useMutation(
    async () => {
      if (errorMessages.length > 0) {
        return
      }

      const params = {
        code,
        type,
        percentageOff: type === 'percentage' ? percentageOff : undefined,
        fixedAmountMinorOff: type === 'fixed' ? fixedAmountMinorOff : undefined,
        durationMonths,
        validForNewSubscriptionsFrom,
        validForNewSubscriptionsTo,
      }

      if (existingDiscount) {
        return api.put<Discount>(
          `/admin/discounts/${existingDiscount.id}`,
          params
        )
      } else {
        return api.post<Discount>(`/admin/discounts`, params)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-discounts')
        closeModal()
      },
    }
  )

  return (
    <Modal
      heading={`${existingDiscount ? 'Oppdater' : 'Opprett'} rabattkode`}
      closeModal={closeModal}
    >
      <div className="space-y-4">
        <label className="flex flex-col space-y-1">
          <div className="flex justify-between">
            <span>Kode</span>
            <button
              className="underline"
              type="button"
              onClick={() => {
                setCode(
                  Math.random().toString(36).substring(2, 10).toUpperCase()
                )
              }}
            >
              Lag tilfeldig kode
            </button>
          </div>
          <input
            value={code}
            onChange={(event) => setCode(event.target.value)}
            placeholder="ABCD1234"
            className="p-2 border"
          />
        </label>

        <label className="flex flex-col space-y-1">
          <span>Type</span>
          <select
            value={type}
            onChange={(event) =>
              setType(event.target.value as 'percentage' | 'fixed')
            }
            className="p-2 border"
          >
            <option value="percentage">Prosent avslag</option>
            <option value="fixed">Beløp avslag</option>
          </select>
        </label>

        {type === 'percentage' && (
          <label className="flex flex-col space-y-1">
            <span>Prosent</span>
            <div className="flex items-center space-x-2">
              <input
                value={percentageOffString}
                onChange={(event) =>
                  setPercentageOffString(
                    event.target.value.replace(/[^0-9.]/g, '')
                  )
                }
                placeholder="Prosent avslag"
                className="p-2 border flex-1"
              />
              <span className="flex-1 text-right">{percentageOff}%</span>
            </div>
          </label>
        )}
        {type === 'fixed' && (
          <label className="flex flex-col space-y-1">
            <span>Beløp</span>
            <div className="flex items-center space-x-2">
              <input
                value={fixedAmountMajorOffString}
                onChange={(event) =>
                  setFixedAmountMajorOffString(
                    event.target.value.replace(/[^0-9.]/g, '')
                  )
                }
                placeholder="Beløp avslag"
                className="p-2 border flex-1"
              />
              <span className="flex-1 text-right">
                {formatPrice(fixedAmountMinorOff)}
              </span>
            </div>
          </label>
        )}

        <label className="flex flex-col space-y-1">
          <span>Varighet</span>
          <select
            value={String(durationMonths)}
            onChange={(event) =>
              setDurationMonths(parseInt(event.target.value))
            }
            className="p-2 border"
          >
            <option value="-1">For alltid</option>
            {new Array(24).fill(0).map((_, index) => {
              const months = index + 1
              return (
                <option key={months} value={String(months)}>
                  {months} måned{months === 1 ? '' : 'er'}
                </option>
              )
            })}
          </select>
        </label>

        <div className="space-y-1">
          <div className="flex space-x-2">
            <label className="flex-1 flex flex-col space-y-1">
              <span>Gyldig fra</span>
              <input
                type="date"
                value={validForNewSubscriptionsFrom}
                onChange={(event) =>
                  setValidForNewSubscriptionsFrom(event.target.value)
                }
                className="p-2 border"
              />
            </label>

            <label className="flex-1 flex flex-col space-y-1">
              <span>Gyldig til</span>
              <input
                type="date"
                value={validForNewSubscriptionsTo}
                onChange={(event) =>
                  setValidForNewSubscriptionsTo(event.target.value)
                }
                className="p-2 border"
              />
            </label>
          </div>
          <div className="text-slate-500 text-xs">
            I perioden denne koden er gyldig kan brukere opprette nye
            abonnementer.
          </div>
        </div>

        {submitAttempted && errorMessages.length > 0 && (
          <div className="space-y-1">
            {errorMessages.map((message) => (
              <div key={message} className="text-red-500 text-sm">
                {message}
              </div>
            ))}
          </div>
        )}
        {mutation.error ? (
          <div className="text-red-500 text-sm">Noe gikk galt. Prøv igjen.</div>
        ) : null}

        <div className="flex justify-end items-center space-x-4 pt-4">
          <button onClick={closeModal}>Avbryt</button>
          <Button
            onClick={() => {
              setSubmitAttempted(true)
              if (errorMessages.length === 0) {
                mutation.mutate()
              }
            }}
            loading={mutation.isLoading}
          >
            {existingDiscount ? 'Oppdater' : 'Opprett'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const DiscountsPage = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
