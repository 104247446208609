import { cx } from '../utils/tailwind'
import { Spinner } from './Spinner'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  outline?: boolean
}

export const Button = ({
  className,
  disabled,
  loading,
  outline,
  children,
  type = 'button',
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={cx(
        'relative px-3 py-1 rounded border border-theme-blue-500',
        outline ? '' : 'bg-theme-blue-500',
        loading && 'cursor-wait',
        disabled &&
          'bg-theme-black-500 border-theme-black-500 cursor-not-allowed',
        className
      )}
      disabled={disabled || loading}
      type={type}
      {...rest}
    >
      {children}
      {loading && (
        <div className="absolute -inset-[1px] flex items-center justify-center rounded bg-[rgb(255,255,255,0.5)]">
          <Spinner className="w-5 h-5" />
        </div>
      )}
    </button>
  )
}
