import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { envVar } from './envVar'

const SENTRY_DSN = envVar('SENTRY_DSN')

if (SENTRY_DSN && SENTRY_DSN != '') {
  Sentry.init({
    dsn: SENTRY_DSN,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
