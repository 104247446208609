import { envVar } from '../envVar'

type Method = 'get' | 'post' | 'put' | 'delete' | 'patch'

const _fetch = (method: Method) => {
  return async <ResBody = any, ReqBody = any>(path: string, body?: ReqBody) => {
    const headers: HeadersInit = {
      Accept: 'application/json',
    }
    if (method === 'post' || method === 'put' || method === 'patch') {
      headers['Content-Type'] = 'application/json'
    }

    const response = await fetch(`${envVar('BACKEND_URL')}${path}`, {
      credentials: 'include',
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    })

    if (response.ok) {
      return (await response.json()) as ResBody
    } else {
      const error = await response.json()
      throw new Error(error.message)
    }
  }
}

export interface ApiError extends Error {
  errorCode: string
}

export const api = {
  get: _fetch('get'),
  post: _fetch('post'),
  put: _fetch('put'),
  delete: _fetch('delete'),
  patch: _fetch('patch'),
}
