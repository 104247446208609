import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SubscriptionProductSerialNumberCell } from '../components/CellEditableSerialNumber'
import { SubscriptionProductStatusCell } from '../components/CellEditableStatus'
import { Layout } from '../components/Layout'
import { Spinner } from '../components/Spinner'
import { Sorting, Table, TableColumn } from '../components/Table'
import { api } from '../lib/api'
import {
  Order,
  OrderPayment,
  Subscription,
  SubscriptionProduct,
} from '../lib/Types'

type OrderQueryData = {
  order: Order
  payments: OrderPayment[]
  subscriptionsWithProducts: Array<
    Subscription & { products: SubscriptionProduct[] }
  >
}
const useOrderQuery = (orderId?: string) => {
  return useQuery(
    ['order', orderId],
    async () => api.get<OrderQueryData>(`/admin/orders/${orderId}`),
    { enabled: Boolean(orderId), keepPreviousData: true }
  )
}

const paymentColumns: TableColumn<OrderPayment>[] = [
  {
    key: 'id',
    title: 'ID',
    render: (row) => row.id.toString(),
    sortable: true,
  },
  {
    key: 'payment_status',
    title: 'Payment Status',
    render: (row) => row.payment_status.toString(),
    sortable: true,
  },
  {
    key: 'amount',
    title: 'Amount',
    render: (row) => row.amount_minor.toString(),
    sortable: true,
  },
  {
    key: 'due_date',
    title: 'Due Date',
    render: (row) => row.due_date.toString(),
    sortable: true,
  },
  {
    key: 'psp_reference',
    title: 'PSP Reference',
    render: (row) => row.psp_reference?.toString(),
    sortable: true,
  },

  {
    key: 'created',
    title: 'Created',
    render: (row) => row.created.toString(),
    sortable: true,
  },
  {
    key: 'updated',
    title: 'Updated',
    render: (row) => row.updated_at.toString(),
    sortable: true,
  },
  /*  {
    key: 'order_id',
    title: 'Order ID',
    render: (row) => row.order_id.toString(),
    sortable: true,
  },
  {
    key: 'checkout_session_id',
    title: 'Checkout Session ID',
    render: (row) => row.checkout_session_id?.toString(),
    sortable: true,
  },
  */
]

const productColumns: TableColumn<SubscriptionProduct>[] = [
  {
    key: 'Serial nummere',
    title: 'Serienummere',
    render: (row) => (
      <SubscriptionProductSerialNumberCell
        subscriptionProductId={row.id}
        serialNumbers={row.serial_numbers!}
        defaultOpen={false}
      />
    ),
    sortable: true,
  },
  {
    key: 'product_status',
    title: 'Produkt Status',
    render: (row) => <SubscriptionProductStatusCell row={row} />,
    sortable: true,
  },
  {
    key: 'size',
    title: 'Størrelse',
    render: (row) => row.size.toString(),
    sortable: false,
  },
  {
    key: 'id',
    title: 'Database ID',
    render: (row) => row.id.toString(),
    sortable: false,
  },
  {
    key: 'created',
    title: 'Created',
    render: (row) => row.created.toString(),
    sortable: true,
  },
  {
    key: 'updated',
    title: 'Updated',
    render: (row) => row.updated_at.toString(),
    sortable: true,
  },
  /*  {
    key: 'order_id',
    title: 'Order ID',
    render: (row) => row.order_id.toString(),
    sortable: true,
  },
  */
]

const Content = ({ data }: { data: OrderQueryData }) => {
  const order = data.order

  const [sorting, setSorting] = useState<Sorting>({
    key: 'created',
    order: 'desc',
  })

  return (
    <>
      <h2 className="text-2xl">Ordre</h2>

      <div className="columns-2 mt-5">
        <h3 className="text-lg">Kunde</h3>
        <ul>
          <li>Navn: {order.name}</li>
          <li>Epost: {order.email} </li>
          <li>
            Adresse: {order.street}, {order.postal_code} {order.city}
          </li>
          <li>Kunde type: {order.customer_type} </li>
        </ul>
        <h3 className="text-lg">Metadata</h3>
        <ul>
          <li>Ordre ID: {order.id}</li>
          <li>User ID: {order.user_id}</li>
          <li>Created: {JSON.stringify(order.created)}</li>
          <li>Updated: {JSON.stringify(order.updated_at)}</li>
          <li>
            Produkt data på ordre objekt: {JSON.stringify(order.products)}
          </li>
        </ul>
      </div>

      <div className="flex-1 flex-col mt-5">
        {/** TODO: add button functionality */}
        <p className="text-base">
          Pris: {order.price_minor / 100} kr
          <button className="rounded p-2 m-2 bg-slate-200">
            Rediger pris på ordre
          </button>
        </p>
        <p className="max-w-sm">
          Har noe unormalt skjedd med abonnementet kan du gjør en ekstra
          betaling her. Kunden vil bli belastet prisen på ordren.
        </p>
        {/* <button
          className="rounded p-2 m-2 bg-slate-200"
          onClick={() => newOrderPayment(data.order.id, data.order.user_id)}
        >
          Gjennomfør ny betaling
        </button> */}
      </div>

      <div className="flex mt-10 mb-10">
        <div className="m-2">
          <h2 className="text-2xl">Leieavtaler på ordre</h2>
          <p className=" text-slate-400 text-xs">
            NB, det er en leieavtale per barn. <br /> Forslag på navn dette kan
            kalles produktavtale, subabonnement, produktgruppe,
          </p>

          {data.subscriptionsWithProducts.map((subscription) => (
            <div className=" bg-emerald-300 p-2 my-2">
              <h3 className="text-lg">ID: {subscription.id}</h3>
              <ul className="my-5">
                <li>Navnelapp: {subscription.product_name_tag}</li>
                <li>Status: {subscription.subscription_status}</li>
                <li>Created: {JSON.stringify(subscription.created)}</li>
                <li>Updated: {JSON.stringify(subscription.updated_at)}</li>
              </ul>
              <Table
                data={subscription.products}
                columns={productColumns}
                sorting={sorting}
                onSort={setSorting}
              />
            </div>
          ))}
        </div>
        <div className="m-2">
          <h2 className=" text-2xl">Betalinger</h2>
          <Table
            data={data.payments}
            columns={paymentColumns}
            sorting={sorting}
            onSort={setSorting}
          />
        </div>
      </div>
    </>
  )
}

export const OrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const orderQuery = useOrderQuery(orderId)

  return (
    <Layout>
      {orderId && orderQuery.data ? (
        <Content data={orderQuery.data} />
      ) : orderQuery.isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>Didn't find what you're looking for...</div>
      )}
    </Layout>
  )
}
