import { flatten, sum } from 'lodash'
import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { Sorting, Table, TableColumn } from '../components/Table'
import { TextInput } from '../components/TextInput'
import { api } from '../lib/api'
import { User } from '../lib/AuthContext'
import useDebounce from '../utils/useDebounce'

const columns: TableColumn<User>[] = [
  {
    key: 'id',
    title: 'ID',
    render: (user) => {
      return (
        <Link
          to={`/users/${user.id}`}
          className="underline text-blue-500 max-w-[80px] overflow-hidden text-ellipsis block"
        >
          {user.id.replaceAll('-', '')}
        </Link>
      )
    },
    className: 'max-w-[80px]',
  },
  {
    key: 'email',
    title: 'Email',
    render: (user) => user.email,
    sortable: true,
  },
  {
    key: 'phone_number',
    title: 'Phone number',
    render: (user) => user.phone_number,
    sortable: true,
  },
  {
    key: 'name',
    title: 'Name',
    render: (user) => user.name ?? '<no name>',
    sortable: true,
  },
  {
    key: 'address',
    title: 'Address',
    render: (user) =>
      user?.street && user.city && user.postal_code
        ? `${user.street} ${user.postal_code} ${user.city} `
        : '---',
  },
  {
    key: 'is_admin',
    title: 'Admin',
    render: (user) => (user.is_admin ? '✓' : ''),
    className: 'text-right',
  },
]

const Content = () => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const [sorting, setSorting] = useState<Sorting>({ key: 'name', order: 'asc' })
  const usersQuery = useInfiniteQuery(
    ['all-users', sorting.key, sorting.order, debouncedQuery],
    ({ pageParam = 0 }) =>
      api.get<{ data: User[]; hasMoreData: boolean }>(
        `/admin/users?limit=100&offset=${pageParam}&sort_by=${sorting.key}&order_by=${sorting.order}&query=${debouncedQuery}`
      ),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.hasMoreData
          ? sum(pages.map((page) => page.data.length))
          : undefined,
    }
  )

  const users = flatten(usersQuery.data?.pages.map((page) => page.data) ?? [])

  return (
    <div>
      <TextInput
        value={query}
        onValue={setQuery}
        placeholder="Søk etter navn, e-post, mobil eller ID"
        className="mb-4"
      />

      <Table
        data={users}
        columns={columns}
        sorting={sorting}
        onSort={setSorting}
        loading={
          usersQuery.isLoading ||
          usersQuery.isFetching ||
          usersQuery.isFetchingNextPage
        }
        fetchMore={
          usersQuery.hasNextPage ? usersQuery.fetchNextPage : undefined
        }
      />
    </div>
  )
}

export const UsersPage = () => {
  return (
    <Layout>
      <h2 className="text-lg my-4">Kunder</h2>
      <Content />
    </Layout>
  )
}
