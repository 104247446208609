import { useState } from 'react'
import { OrderPayment } from '../lib/Types'
import { formatDate } from '../utils/formatDate'
import { Sorting, Table, TableColumn } from './Table'

export const PaymentsTable = ({ payments }: { payments: OrderPayment[] }) => {
  const [sorting, setSorting] = useState<Sorting>({
    key: 'created',
    order: 'desc',
  })

  return (
    <div className="my-5">
      <h2 className=" text-2xl">Betalinger</h2>
      {payments ? (
        <Table
          data={payments}
          columns={paymentColumns}
          sorting={sorting}
          onSort={setSorting}
        />
      ) : (
        <p>Ingen betalinger</p>
      )}
    </div>
  )
}

const paymentColumns: TableColumn<OrderPayment>[] = [
  {
    key: 'created',
    title: 'Opprettet',
    render: (row) => formatDate(row.created),
    sortable: true,
  },
  {
    key: 'payment_status',
    title: 'Status Betaling',
    render: (row) => row.payment_status.toString(),
    sortable: true,
  },
  {
    key: 'amount',
    title: 'Beløp',
    render: (row) =>
      row.amount_minor.toString().slice(0, -2) +
      ',' +
      row.amount_minor.toString().slice(-2) +
      'kr',
    //sortable: true,
  },
  {
    key: 'due_date',
    title: 'Due Date',
    render: (row) => formatDate(row.due_date),
    sortable: true,
  },
  {
    key: 'psp_reference',
    title: 'PSP Referanse',
    render: (row) => row.psp_reference?.toString(),
    //sortable: true,
  },
  {
    key: 'updated',
    title: 'Sist Oppdatert',
    render: (row) => formatDate(row.updated_at),
    sortable: true,
  },
  {
    key: 'id',
    title: 'ID',
    render: (row) => row.id.toString(),
    //sortable: true,
  },
  /*  {
      key: 'order_id',
      title: 'Order ID',
      render: (row) => row.order_id.toString(),
      sortable: true,
    },
    {
      key: 'checkout_session_id',
      title: 'Checkout Session ID',
      render: (row) => row.checkout_session_id?.toString(),
      sortable: true,
    },
    */
]
