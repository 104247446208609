import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SubscriptionProductSerialNumberCell } from '../../components/CellEditableSerialNumber'
import { SubscriptionSizeCell } from '../../components/CellEditableSize'
import { SubscriptionProductStatusCell } from '../../components/CellEditableStatus'
import { Layout } from '../../components/Layout'
import { PaymentsTable } from '../../components/PaymentsTable'
import { Spinner } from '../../components/Spinner'
import { Sorting, Table, TableColumn } from '../../components/Table'
import { api } from '../../lib/api'
import { SubscriptionProduct, UserViewQueryData } from '../../lib/Types'
import { formatDate } from '../../utils/formatDate'
import { CancelSubscription } from './CancelSubscription'
import { Subscription } from '../../lib/Types'

const useUserViewQuery = (userId?: string) => {
  return useQuery(
    ['user', userId],
    async () => api.get<UserViewQueryData>(`/admin/users/${userId}`),
    { enabled: Boolean(userId), keepPreviousData: true }
  )
}

const updateUser = (userId?: string) => {
  return useMutation<
    UserViewQueryData,
    unknown,
    {
      name: string
      address: {
        street: string
        postalCode: string
        city: string
      }
      phoneNumber: string
      email: string
    }
  >(['user', userId], async (values) =>
    api.put<UserViewQueryData>(`/admin/users/${userId}`, values)
  )
}

const subscriptionProductNameTag = (subscriptionId?: string) => {
  return useMutation<
    Subscription,
    unknown,
    {
      productNameTag: string
    }
  >(['subscription', subscriptionId], async (values) =>
    api.put<Subscription>(
      `/admin/subscriptions/updateProductNameTag/${subscriptionId}`,
      values
    )
  )
}

const productColumns: TableColumn<SubscriptionProduct>[] = [
  {
    key: 'Serial nummere',
    title: 'Serienummere',
    render: (row) => (
      <SubscriptionProductSerialNumberCell
        subscriptionProductId={row.id}
        serialNumbers={row.serial_numbers!}
        defaultOpen={false}
      />
    ),
    sortable: true,
  },
  {
    key: 'product_status',
    title: 'Produktstatus',
    render: (row) => <SubscriptionProductStatusCell row={row} />,
    sortable: true,
  },
  {
    key: 'size',
    title: 'Størrelse og Farge',
    render: (row) => <SubscriptionSizeCell row={row} />,
    sortable: false,
  },
  {
    key: 'created',
    title: 'Opprettet',
    render: (row) => formatDate(row.created),
    sortable: true,
  },
  {
    key: 'updated',
    title: 'Sist oppdatert',
    render: (row) => formatDate(row.updated_at),
    sortable: true,
  },
]

const UserInformation = ({ data }: { data: UserViewQueryData }) => {
  const user = data.user
  const order = data.additionalData.order

  const [editState, setEditState] = useState<boolean>(false)
  const [username, setUsername] = useState<string>(user.name)
  const [email, setEmail] = useState<string>(user.email)
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone_number)
  const [city, setCity] = useState<string>(user.city)
  const [postcode, setPostcode] = useState<string>(user.postal_code)
  const [street, setStreet] = useState<string>(user.street)

  const updateUserMutation = updateUser(user.id)

  const EditUserInDb = async () => {
    updateUserMutation.mutateAsync({
      name: username,
      address: {
        street: street,
        postalCode: postcode,
        city: city,
      },
      phoneNumber: phoneNumber,
      email: email,
    })
  }
  return (
    <div>
      <div className="flex">
        <h2 className="text-2xl">Kunde</h2>
        {editState === false ? (
          <button
            className="border-2 ml-4 text-lg px-4 border-black rounded-md"
            onClick={() => setEditState(true)}
          >
            {' '}
            edit
          </button>
        ) : (
          <button
            className="border-2 ml-4 text-lg px-4 border-black rounded-md"
            onClick={() => {
              EditUserInDb(), setEditState(false)
            }}
          >
            {' '}
            save
          </button>
        )}
      </div>

      {!editState && (
        <div className="grid grid-cols-[auto_1fr] gap-x-4">
          <div>Navn</div>
          <div>{username}</div>
          <div>E-post</div>
          <div>{email} </div>
          <div>Telefon</div>
          <div>{phoneNumber} </div>
          <div>Adresse</div>
          <div>
            {street}, {postcode} {city}
          </div>

          {order && (
            <>
              <div>Kunde type</div>
              <div>{order.customer_type} </div>
              <div>Pris på abonnement</div>
              <div>{order.price_minor / 100} kr</div>
            </>
          )}
        </div>
      )}

      {editState && (
        <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-1">
          <div>Navn</div>
          <input
            className="border-2 border-black px-1 w-fit"
            placeholder={'Navn'}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          ></input>
          <div>E-post</div>
          <input
            className="border-2 border-black px-1 w-fit"
            type="email"
            placeholder={'E-post'}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <div>Telefon</div>
          <input
            className="border-2 border-black px-1 w-fit"
            type="tel"
            placeholder={'Telefonnummer'}
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
          ></input>
          <div>Adresse</div>
          <div className=" ">
            <input
              className="border-2 border-black px-1 w-fit"
              type=""
              placeholder={'Gateadresse'}
              onChange={(e) => setStreet(e.target.value)}
              value={street}
            ></input>
            <input
              className="border-2 mx-2 border-black px-1 w-fit"
              type=""
              placeholder={'Postnummer'}
              onChange={(e) => setPostcode(e.target.value)}
              value={postcode}
            ></input>
            <input
              className="border-2 border-black px-1 w-fit "
              type=""
              placeholder={'Poststed'}
              onChange={(e) => setCity(e.target.value)}
              value={city}
            ></input>
          </div>

          {order && (
            <>
              <div>Kundetype</div>
              <div>{order.customer_type} </div>
              <div>Pris på abonnement</div>
              <div>{order.price_minor / 100} kr</div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const ParkdressName = ({
  name,
  subscriptionId,
}: {
  name: string
  subscriptionId: string
}) => {
  const [editNameState, setEditNameState] = useState<boolean>(false)
  const [kidName, setKidName] = useState<string>(name)

  const updateProductNameTagMutation =
    subscriptionProductNameTag(subscriptionId)

  const EditSubscriptionProductNameInDb = async () => {
    updateProductNameTagMutation.mutateAsync({
      productNameTag: kidName,
    })
  }

  return (
    <div className="flex">
      {' '}
      {editNameState ? (
        <input
          className="border-2 border-black px-1 w-fit"
          placeholder={'Navn'}
          onChange={(e) => setKidName(e.target.value)}
          value={kidName}
        ></input>
      ) : (
        <h3 className="text-lg">{kidName}</h3>
      )}
      {editNameState ? (
        <button
          onClick={() => {
            EditSubscriptionProductNameInDb(), setEditNameState(false)
          }}
          className="ml-4 border-2 px-2 border-black rounded-md"
        >
          {' '}
          save
        </button>
      ) : (
        <button
          onClick={() => setEditNameState(true)}
          className="ml-4 border-2 px-2 border-black rounded-md"
        >
          {' '}
          edit
        </button>
      )}
    </div>
  )
}

const Content = ({ data }: { data: UserViewQueryData }) => {
  const payments = data.additionalData.payments
  const subscriptionsWithProducts =
    data.additionalData.subscriptionsWithProducts

  const [sorting, setSorting] = useState<Sorting>({
    key: 'created',
    order: 'desc',
  })

  return (
    <div>
      <UserInformation data={data}></UserInformation>
      <div className="flex flex-col my-10">
        <div className="">
          <h2 className="text-2xl">
            {subscriptionsWithProducts.length} barn på abonnementet
          </h2>
          {subscriptionsWithProducts ? (
            <>
              <p className=" text-slate-400 text-xs">
                NB! Et barn kan har flere parkdresser i loggen sin.
              </p>
              {subscriptionsWithProducts.map((subscription) => (
                <div className=" bg-emerald-300 p-2 my-5 rounded-lg">
                  <ParkdressName
                    subscriptionId={subscription.id}
                    name={subscription.product_name_tag}
                  ></ParkdressName>
                  <h3 className="text-lg float-right m-2">
                    <div>Status: {subscription.subscription_status}</div>

                    {subscription.subscription_status !== 'cancelled' && (
                      <CancelSubscription
                        key={subscription.id}
                        subscriptionId={subscription.id}
                      />
                    )}
                  </h3>
                  <p className="text-xs">ID: {subscription.id}</p>
                  <p className="text-xs">
                    Opprettet: {formatDate(subscription.created)}
                  </p>
                  <p className="text-xs">
                    Sist oppdatert: {formatDate(subscription.updated_at)}
                  </p>

                  <h3 className="my-2"> Parkdresser: </h3>
                  <div className="overflow-x-auto">
                    <Table
                      data={subscription.products}
                      columns={productColumns}
                      sorting={sorting}
                      onSort={setSorting}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>Ingen abonnementet</>
          )}
        </div>

        <PaymentsTable payments={payments} />
      </div>
    </div>
  )
}

export const UserPage = () => {
  const { userId } = useParams<{ userId: string }>()
  const userViewQuery = useUserViewQuery(userId)

  return (
    <Layout>
      {userId && userViewQuery.data ? (
        <Content data={userViewQuery.data} />
      ) : userViewQuery.isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>Didn't find what you're looking for...</div>
      )}
    </Layout>
  )
}
