import { NavLink } from 'react-router-dom'
import { cx } from '../utils/tailwind'

const MenuItem = ({
  to,
  children,
  position = 'in-between',
  className,
}: {
  to: string
  children: string
  position?: 'first' | 'in-between' | 'last'
  className?: string
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cx(
          'py-2 px-4 text-sm font-medium border border-gray-200',
          {
            first: 'rounded-l-lg border-r-0',
            'in-between': 'border-r-0',
            last: 'rounded-r-lg',
          }[position],
          isActive ? 'bg-gray-100 dark:bg-gray-400' : 'bg-white',
          className
        )
      }
    >
      {children}
    </NavLink>
  )
}

export const Menu = () => {
  return (
    <div className="inline-flex">
      <MenuItem to="/dashboard" position="first">
        Dashboard
      </MenuItem>
      <MenuItem to="/orders">Ordre</MenuItem>
      <MenuItem to="/users">Kunder</MenuItem>
      <MenuItem to="/kindergartens">Barnehager</MenuItem>
      <MenuItem to="/discounts">Rabatter</MenuItem>
      <MenuItem to="/product-return" position="last">
        Parkdresser
      </MenuItem>
    </div>
  )
}
