import { format, parseISO } from 'date-fns'
import nbLocale from 'date-fns/locale/nb'

export const formatDate = (date: string | Date, pattern?: string) => {
  const parsed = typeof date === 'string' ? parseISO(date) : date
  const formattedDate: string = format(
    parsed,
    pattern ?? 'dd. MMM yyyy HH:mm',
    {
      locale: nbLocale,
    }
  )
  return formattedDate
}
