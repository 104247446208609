export type Subscription = {
  id: string
  created: string
  updated_at: string
  user_id: string
  order_id: string
  subscription_status:
    | 'pending-subscription-start'
    | 'pending-payment'
    | 'active'
    | 'cancelled'
    | 'paused'
  product_name_tag: string
}

export type SubscriptionProductSerialNumber = {
  type: 'unknown' | 'innerWear' | 'outerWear'
  serialNumber: string
}

export type SubscriptionProduct = {
  id: string
  created: string
  updated_at: string
  color: 'red' | 'green'
  subscription_id: string
  status: 'waiting-for-sending' | 'active' | 'returning' | 'returned'
  size: string
  serial_numbers?: SubscriptionProductSerialNumber[]
}

export type UserViewQueryData = {
  user: {
    id: string
    phone_number: string
    email: string
    street: string
    postal_code: string
    city: string
    name: string
    is_admin: string
  }
  additionalData: {
    order: Order
    payments: OrderPayment[]
    subscriptionsWithProducts: Array<
      Subscription & { products: SubscriptionProduct[] }
    >
  }
}

export type Order = {
  id: string
  created: string
  updated_at: string
  name: string
  email: string
  postal_code?: string
  street?: string
  city?: string
  products: Array<{ name: string; size: string; color?: 'red' | 'green' }>
  customer_type: 'b2b' | 'b2c'
  price_minor: number
  user_id: string
  discount_code?: string
}

export type OrderPayment = {
  id: string
  created: string
  updated_at: string
  order_id: string
  checkout_session_id?: string
  payment_status:
    | 'pending-recurring'
    | 'in-progress'
    | 'paid'
    | 'payment-failed'
  psp_reference?: string
  amount_minor: number
  due_date: string
}

export const sizes = {
  red: ['86', '92', '98', '104', '110', '116', '122'],
  green: ['80', '86', '92', '98', '104', '110', '116', '122', '128'],
} as const

export type Size = typeof sizes['red' | 'green'][number]
