import { useCallback, useState } from 'react'
import { ProductRequest } from './DashboardPage'
import { ProductRequestTag } from './ProductRequestTag'

export type FiltersType = {
  includeB2b: boolean
  includeB2c: boolean
  includeSwapRequests: boolean
  includeNewOrders: boolean
  b2bLocationName: string
  userName: string
}

export const ProductRequestFilters = ({
  filters,
  setFilters,
}: {
  filters: FiltersType
  setFilters: (filters: FiltersType) => void
}) => {
  return (
    <div className="px-4 py-2 rounded-lg bg-gray-200 space-y-1">
      <div className="text-lg">Filtrering</div>
      <div className="flex space-x-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeB2b}
            onChange={() =>
              setFilters({ ...filters, includeB2b: !filters.includeB2b })
            }
          />
          <ProductRequestTag type="b2b" className="ml-2" />
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeB2c}
            onChange={() =>
              setFilters({ ...filters, includeB2c: !filters.includeB2c })
            }
          />
          <ProductRequestTag type="b2c" className="ml-2" />
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeNewOrders}
            onChange={() =>
              setFilters({
                ...filters,
                includeNewOrders: !filters.includeNewOrders,
              })
            }
          />
          <ProductRequestTag type="new" className="ml-2" />
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeSwapRequests}
            onChange={() =>
              setFilters({
                ...filters,
                includeSwapRequests: !filters.includeSwapRequests,
              })
            }
          />
          <ProductRequestTag type="swap" className="ml-2" />
        </label>
      </div>
      <div>
        <label className="flex items-center">
          <div className="mr-2 w-[88px]">Barnehage</div>
          <input
            type="text"
            className="border border-black rounded"
            onChange={(event) =>
              setFilters({
                ...filters,
                b2bLocationName: event.target.value,
              })
            }
          />
        </label>
      </div>
      <div>
        <label className="flex items-center">
          <div className="mr-2 w-[88px]">Kunde</div>
          <input
            type="text"
            className="border border-black rounded"
            onChange={(event) =>
              setFilters({
                ...filters,
                userName: event.target.value,
              })
            }
          />
        </label>
      </div>
    </div>
  )
}

export const useProductRequestsFilters = () => {
  const [filters, setFilters] = useState<FiltersType>({
    includeB2b: true,
    includeB2c: true,
    includeSwapRequests: true,
    includeNewOrders: true,
    b2bLocationName: '',
    userName: '',
  })

  const filterProductRequests = useCallback(
    (productRequests: ProductRequest[]) => {
      let filteredProductRequests = productRequests

      if (!filters.includeB2b) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) => productRequest.customerType !== 'b2b'
        )
      }
      if (!filters.includeB2c) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) => productRequest.customerType !== 'b2c'
        )
      }
      if (
        (filters.includeSwapRequests && filters.includeNewOrders) ||
        (!filters.includeSwapRequests && !filters.includeNewOrders)
      ) {
        // do nothing
      } else if (filters.includeSwapRequests) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) => productRequest.isSwapRequest
        )
      } else if (filters.includeNewOrders) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) => !productRequest.isSwapRequest
        )
      }
      if (filters.b2bLocationName) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) =>
            productRequest.b2bLocationName
              ?.toLowerCase()
              .includes(filters.b2bLocationName.toLowerCase())
        )
      }
      if (filters.userName) {
        filteredProductRequests = filteredProductRequests.filter(
          (productRequest) =>
            productRequest.userName
              ?.toLowerCase()
              .includes(filters.userName.toLowerCase())
        )
      }

      return filteredProductRequests
    },
    [filters]
  )

  return {
    FilterComponent: (
      <ProductRequestFilters filters={filters} setFilters={setFilters} />
    ),
    filterProductRequests,
  }
}
