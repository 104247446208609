import { FC, useState } from 'react'
import { useMutation } from 'react-query'
import { api, ApiError } from '../lib/api'
import { queryClient } from '../lib/reactQuery'
import { sizes, SubscriptionProduct } from '../lib/Types'
import EditIcon from './EditIcon'

export const useSizeMutation = () => {
  return useMutation<
    void,
    ApiError,
    {
      subscriptionId: SubscriptionProduct['id']
      size: SubscriptionProduct['size']
      color: SubscriptionProduct['color']
    }
  >(
    async (args) => {
      return api.post(`/admin/subscriptions/${args.subscriptionId}/swap`, {
        color: args.color,
        size: args.size,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('order')
        queryClient.invalidateQueries('product-requests')
        queryClient.invalidateQueries('user')
      },
    }
  )
}

export const SubscriptionSizeCell: FC<{ row: SubscriptionProduct }> = ({
  row,
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [newSize, setNewSize] = useState<SubscriptionProduct['size']>(row.size)
  const [newColor, setNewColor] = useState<'red' | 'green'>(row.color)

  const mutation = useSizeMutation()
  const colorOptions = ['green', 'red']
  return (
    <div className="flex items-center">
      <select
        className={`${
          isEditable
            ? 'text-black border-2 border-black disabled:true mr-1'
            : 'mr-1'
        }`}
        name="size"
        id="size"
        disabled={!isEditable}
        onChange={(e) => setNewSize(e.target.value)}
        value={isEditable ? newSize : row.size}
      >
        {sizes[newColor].map((size, i) => (
          <option key={`size_${i}`} value={size}>
            {size}
          </option>
        ))}
      </select>

      <select
        className={`${
          isEditable
            ? 'text-black border-2 border-black disabled:true text-right'
            : 'text-right'
        }`}
        name="color"
        id="color"
        disabled={!isEditable}
        onChange={(e) => {
          if (e.target.value === 'red' || e.target.value === 'green') {
            setNewColor(e.target.value)
          }
        }}
        value={isEditable ? newColor : row.color}
      >
        {colorOptions.map((color, i) => (
          <option key={`color_${i}`} value={color}>
            {color}
          </option>
        ))}
      </select>

      <button
        className="border-2 border-black p-1 ml-1 rounded-md text-black"
        onClick={(e) => {
          e.preventDefault()
          if (newSize !== row.size) {
            mutation.mutateAsync({
              subscriptionId: row.subscription_id,
              size: newSize,
              color: newColor
                ? newSize === '80' || newSize === '128'
                  ? 'green'
                  : newColor
                : 'red',
            })
          }

          setIsEditable(!isEditable)
        }}
      >
        {!isEditable && <EditIcon />}
        {isEditable && <p className="text-xs">Save</p>}
      </button>
    </div>
  )
}
