import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { useAuthContext } from '../../lib/AuthContext'

const Content: FC = () => {
  const { signOut } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    signOut().then(() => {
      navigate('/')
    })
  }, [signOut, navigate])

  return null
}

export const SignoutPage: FC = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
